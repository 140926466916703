.all {
  min-height: 100vh;
}

.main {
  min-height: 90vh;
  background-color: hsl(6, 75%, 97%);
  color: hsl(6, 75%, 5%);
}

.footer {
  margin: 0;
  width: 100vw;
  padding: 1rem;
  padding-bottom: 0;
  background-color: hsl(6, 75%, 5%);
  //background-color: red;
  min-height: 10vh;
  //height: 25vh;

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  align-content: flex-start;

  color: #fafafa;

  //position: absolute;
  //bottom: 0;
  //left: 0;
}

.footerLogo {
  flex: 0 0 auto;
  margin-top: -0.5rem;
}

.footerSocialLinks {
  flex: 0 0 auto;
}

.footerLinks {
  flex: 0 0 auto;
  width: 50vw;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  align-content: flex-start;
  padding: 0.5rem 0 0;
  opacity: 0.75;
}

.footerLinksColumn {
  flex: 0 0 auto;
  padding: 0;
}

//
//a:hover {
//  opacity: 1.0;
//  color: rgb(255, 255, 255, 1.0);
//}
