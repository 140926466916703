html,
body {
    padding: 0;
    margin: 0;
    font-family: Quicksand, "Segoe UI", Oxygen, Roboto, system-ui, -apple-system, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif;
    background-color: hsl(6, 75%, 5%);
    color: hsl(6, 75%, 95%);
}

* {
    box-sizing: border-box;
}

a {
    color: inherit;
    text-decoration: none;
    opacity: 0.75;
}

a:hover {
    opacity: 1;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.page {
    padding: 4rem;
}
