.section {
  width: 100vw;
  background-color: hsl(6, 75%, 97%);
  color: hsl(6, 75%, 5%);
  padding: 2rem 4rem;
}

.features {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
}

.feature {
  padding: 1rem;
  /*background-color: rgba(0, 0, 0, 0.1);*/
  border-radius: 0.75rem;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

  display: flex;
  flex-direction: column;

  .icon {
    /*align-self: center;*/
    //width: 3rem;
    //height: 3rem;
    margin-bottom: 0.25rem;
    //color: red;
  }

  .title {
    font-size: large;
    font-weight: bold;

    border-top: white 1px dashed;

    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .description {
    padding: 0.5rem 0;
  }
}
