.section {
    background-color: burlywood;

    width: 100vw;
    /*height: 100vh;*/

    background-image: url(../../media/bg/unsplash/joanna-kosinska-B6yDtYs2IgY-unsplash.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;

    /*clip-path: polygon(0% 0%, 100% 0%, 100% 88%, 74% 87%, 59% 100%, 59% 87%, 0 87%);*/

    color: hsl(6, 75%, 95%);

    padding: 2rem 4rem;
}
