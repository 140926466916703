.filler {
  min-height: 100vh;
}

.missing {
  position: absolute;
  top: 0;
  left: 0;

  background-image: url(../media/bg/unsplash/irina-8feynW1s3VU-unsplash.jpg);
  background-size: cover;

  height: 100vh;
  width: 100vw;
  min-height: 100vh;
  min-width: 100vw;

  color: azure;

  .title {
    padding-left: 10vw;
    padding-right: 40vw;
    padding-top: 20vh;

    //width: 30%;
    //    max-width: 20vw;

    //height: 10vh;
    //min-height: 5vh;

    //font-size: xxx-large;
  }

  .subtitle {
    padding-left: 10vw;
    padding-right: 40vw;
    padding-top: 1rem;
  }
}
