.section {
  width: 100vw;
  height: 100vh;

  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;

  @media (orientation: landscape) {
    background-image: url(../../media/bg/unsplash/ian-wagg-Y6_i7U4Dyu8-unsplash.jpg);
  }

  @media (orientation: portrait) {
    background-image: url(../../media/bg/unsplash/patty-brito-eHOZjZEx7u8-unsplash.jpg);
  }
}

.slogans {
  position: absolute;
  font-weight: bolder;
  padding: 0.25rem 2rem;
  border-radius: 1rem;

  @media (orientation: landscape) {
    left: 5vw;
    top: 5vh;
    background-color: rgb(40, 40, 40, 0.75);
    color: rgb(240, 240, 240, 0.9);
    //background-color: rgb(128, 128, 128, 0.25);
    //color: rgb(10, 10, 0, 0.9);
  }

  @media (orientation: portrait) {
    left: 5vw;
    bottom: 5vh;
    background-color: rgb(40, 40, 40, 0.75);
    color: rgb(240, 240, 240, 0.9);
  }
}

.slogan {
  width: 30vw;
  //height: 10vh;
  min-height: 5vh;
  padding: 0.5rem 0;
}
